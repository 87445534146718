var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashhead"},[_c('div',{staticClass:"dashhead-titles"},[_c('h6',{staticClass:"dashhead-subtitle"},[_vm._v(_vm._s(_vm.subTitle))]),_c('h3',{staticClass:"dashhead-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('hr',{staticClass:"my-3"}),_c('sgv-form',{attrs:{"method":_vm.method,"options":_vm.options},on:{"update:method":function($event){_vm.method=$event}}},[_c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส","validations":[
            {text: 'required!', value: _vm.$v.formData.code.$dirty && _vm.$v.formData.code.$error}
          ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1),_c('DetailFormStockInput',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Stock ID","templateType":_vm.templateType,"stockAssetType":_vm.stockAssetType,"select":"id","validations":[
          {text: 'required!', value: _vm.$v.formData.stockId.$dirty && _vm.$v.formData.stockId.$error}
        ]},model:{value:(_vm.formData.stockId),callback:function ($$v) {_vm.$set(_vm.formData, "stockId", $$v)},expression:"formData.stockId"}}),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"placeholder":"ตัวอย่าง 100 10%","label":"อัตราค่าเสื่อม","validations":[
            {text: 'required!', value: _vm.$v.formData.amountRate.$dirty && _vm.$v.formData.amountRate.$error}
          ]},model:{value:(_vm.formData.amountRate),callback:function ($$v) {_vm.$set(_vm.formData, "amountRate", $$v)},expression:"formData.amountRate"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"placeholder":"ตัวอย่าง 100 10%","label":"อัตราค่าเสื่อมกรมสรรพากร","validations":[
            {text: 'required!', value: _vm.$v.formData.refRate.$dirty && _vm.$v.formData.refRate.$error}
          ]},model:{value:(_vm.formData.refRate),callback:function ($$v) {_vm.$set(_vm.formData, "refRate", $$v)},expression:"formData.refRate"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"placeholder":"ตัวอย่าง 100 10%","label":"มูลค่าสุดท้าย","validations":[
            {text: 'required!', value: _vm.$v.formData.amountFinal.$dirty && _vm.$v.formData.amountFinal.$error}
          ]},model:{value:(_vm.formData.amountFinal),callback:function ($$v) {_vm.$set(_vm.formData, "amountFinal", $$v)},expression:"formData.amountFinal"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"placeholder":"0 0 1 * *","label":"เวลา (cron)"},model:{value:(_vm.formData.cron),callback:function ($$v) {_vm.$set(_vm.formData, "cron", $$v)},expression:"formData.cron"}})],1)],1)]),(_vm.stockAssetId > 0)?[_c('HrDivider',{attrs:{"options":_vm.tabs},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_vm._l((_vm.tabs),function(tab){return [(tab.value === _vm.selectedTab && _vm.stockAssetId > 0)?_c(tab.value,{key:tab.value,tag:"component",attrs:{"stockAssetType":_vm.stockAssetType,"stockAssetId":_vm.stockAssetId,"templateType":_vm.templateType}}):_vm._e()]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }