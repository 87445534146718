import gql from 'graphql-tag'

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($stockAssetType: String!, $stockAssetId: Int!, $orderType: String, $q: FilterInput) {
  orders: listStockAsset${templateType}Order (stockAssetType: $stockAssetType, stockAssetId: $stockAssetId, orderType: $orderType, q: $q) {
    id type name totalPrice parentId
    docId doc {
      id type code name
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
      closedAt closedBy closedUser {id name}
    }
  }
}`
