export * from './dropdown'
export * from './doc'
export * from './order'
import gql from 'graphql-tag'


const listReponse = `
  id code cron amountRate refRate amountFinal
  stockId stock {
    id qty
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
  assetValue {
    stockValue
    currentValue
    rateValue
    finalValue
    percentValue
  }
`

export const LIST_STOCK_ASSET = (templateType) => gql`query LIST_STOCK_ASSET ($stockAssetType: String!, $q: FilterInput) {
  stockAssets: listStockAsset${templateType} (stockAssetType: $stockAssetType, q: $q) {${listReponse}}
}`

const detailResponse = `
  id code stockId cron amountRate refRate amountFinal
`

export const DETAIL_STOCK_ASSET = (templateType) => gql`query DETAIL_STOCK_ASSET ($stockAssetType: String!, $stockAssetId: Int!) {
  stockAsset: detailStockAsset${templateType} (stockAssetType: $stockAssetType, stockAssetId: $stockAssetId) {${detailResponse}}
}`

export const CREATE_STOCK_ASSET = (templateType) => gql`mutation CREATE_STOCK_ASSET ($stockAssetType: String!, $input: StockAsset${templateType}Input!) {
  createStockAsset: createStockAsset${templateType} (stockAssetType: $stockAssetType, input: $input) {${detailResponse}}
}`

export const UPDATE_STOCK_ASSET = (templateType) => gql`mutation UPDATE_STOCK_ASSET ($stockAssetType: String!, $stockAssetId: Int!, $input: StockAsset${templateType}Input!) {
  updateStockAsset: updateStockAsset${templateType} (stockAssetType: $stockAssetType, stockAssetId: $stockAssetId, input: $input) {${detailResponse}}
}`

export const DESTROY_STOCK_ASSET = (templateType) => gql`mutation DESTROY_STOCK_ASSET ($stockAssetType: String!, $stockAssetId: Int!) {
  destroyStockAsset: destroyStockAsset${templateType} (stockAssetType: $stockAssetType, stockAssetId: $stockAssetId) {id}
}`
