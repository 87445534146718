import gql from 'graphql-tag'

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($stockAssetType: String!, $stockAssetId: Int!, $q: FilterInput) {
  docs: listStockAsset${templateType}Doc (stockAssetType: $stockAssetType, stockAssetId: $stockAssetId, q: $q) {
    id type code name remark
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    closedAt closedBy closedUser {id name}
  }
}`
