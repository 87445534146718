import gql from 'graphql-tag'

export const DP_STOCKS = (templateType) => gql`query DP_STOCKS ($stockAssetType: String!, $q: FilterInput) {
  items: listStockAsset${templateType}DropdownStock (stockAssetType: $stockAssetType, q: $q) {
    id qty
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
  }
}`
