<template>
  <div class="">
    <sgv-table
      ref="docList"
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.name" class="no-wrap">
            {{item.name}}
          </td>
          <td v-if="hidden.doc">
            <router-link
              :class="{'disabled': !item.doc.isLink, 'text-muted': !item.doc.isLink}"
              class="text-decoration-none"
              :to="toDetail(item.doc)">
              {{item.doc.code}}
            </router-link>
          </td>
          <td v-if="hidden.totalPrice">
            {{item.totalPrice | comma}}
          </td>
          <td v-if="hidden.createdAt">
            {{ item.doc.createdAt | date }}<br>
            <small class="text-primary">{{item.doc.createdUser.name}}</small>
          </td>
          <td v-if="hidden.approvedAt">
            <template v-if="item.doc.approvedAt">
              <span class="text-warning">{{item.doc.approvedAt | date}}</span><br>
              <small class="text-primary">{{item.doc.approvedUser.name}}</small>
            </template>
          </td>
          <td v-if="hidden.closedAt">
            <template v-if="item.doc.closedAt">
              <span class="text-success">{{item.doc.closedAt | date}}</span><br>
              <small class="text-primary">{{item.doc.closedUser.name}}</small>
            </template>
          </td>
        </tr>
      </template>

      <sgv-input-select
        slot="action"
        :options="orderTypes"
        v-model="selectedType"
        select="value"
        class="mb-2">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>
    </sgv-table>
  </div>
</template>

<script>
import round from 'lodash/round'
import retainMixin from '@/mixins/retain-mixin'
import { LIST_ORDER } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    stockAssetId: {
      type: Number,
      required: true
    },
    stockAssetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `StockAsset${this.$form.capitalize(this.stockAssetType)}DetailOrder`,
      rFields: ['filter', 'options', 'selectedType'],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'totalPrice', 'createdAt', 'approvedAt', 'closedAt', 'doc'],
        column: null,
        search: null,
        toolbar: null,
      },
      items: [],
      orderTypes: [
        {text: 'ทั้งหมด', value: null},
        {text: 'ค่าเสื่อมสะสม', value: 'deduction'},
        {text: 'ค่าเสื่อม', value: 'expense'},
        {text: 'ค่าเสื่อมบวกกลับ', value: 'excess'},
      ],
      selectedType: null
    }
  },
  computed: {
    headers () {
      return [
        {text: 'ประเภท', value: 'name'},
        {text: 'เอกสาร', value: 'doc', sort: true, filter: true},
        {text: `มูลค่า (${this.totalPrice})`, value: 'totalPrice'},
        {text: 'วันที่บันทึก', value: 'createdAt', sort: true},
        {text: 'วันที่อนุมัติ', value: 'approvedAt', sort: true},
        {text: 'วันที่จบ', value: 'closedAt', sort: true},
      ]
    },
    totalPrice () {
      if (!this.selectedType) return 0
      
      const x = this.items.reduce((t,v) => {
        return t += +v.totalPrice
      }, 0)
      return this.$form.addComma(round(x, 2))
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          stockAssetType: this.stockAssetType,
          stockAssetId: this.stockAssetId,
          orderType: this.selectedType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
      result (res) {
        this.items = res.data.orders
        .filter(order => order.parentId)
        .map(order => {
          return {
            ...order,
            doc: {
              ...order.doc,
              isLink: this.$auth.hasRole(`doc:${order.doc.type}:read`)
            }
          }
        })
      }
    }
  },
  methods: {
    getFilter (v) {
      const obj = {
        limit: v.limit,
        offset: v.offset,
        order: v.order,
        params: {...v.params}
      }
      if (obj.order?.includes('doc')) {
        obj.order = obj.order.replace('doc', 'code')
      }
      return obj
    },
    toDetail (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>
